import React from 'react';
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export function ParamsToLocalStorage() {
  const [searchParams] = useSearchParams();

  const paramsToLocalStorage = useCallback((key:string, clearLSWhenNotExist?:boolean, localStorageKey?:string, additionalClearLSKey?:string[]) => {
    const value = searchParams.get(key);
    if (value) {
      localStorage.setItem(localStorageKey || key, value);
    }
    
    if (clearLSWhenNotExist && !value) {
      localStorage.removeItem(localStorageKey || key);
      if (Array.isArray(additionalClearLSKey)) {
        for (const lsKey of additionalClearLSKey) {
          localStorage.removeItem(lsKey);
        }
      }
    }
  }, [searchParams]);

  const loadLocale = useCallback(() => {
    paramsToLocalStorage("locale");
    paramsToLocalStorage("appId");
    paramsToLocalStorage("token", true, "refreshToken", ["accessToken", "accessTokenExpiryDate", "refreshTokenExpiryDate"]);
    paramsToLocalStorage("key");
  }, [searchParams]);

  useEffect(loadLocale, [loadLocale]);
  return <></>
}