import React from 'react'
import { privateApi } from "..";

export async function getUser() {
  const response = await privateApi.get("/user");

  return response.data;
}

export async function getUserCurrentPass() {
  const response = await privateApi.get("/user/currentPass");

  return response.data;
}

export async function postUserSwitchMode(appId:string) {
  const response = await privateApi.post("/user/switchMode", { appId });

  return response.data;
}