import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col, Stack, ListGroup } from 'react-bootstrap';
import dollar from './dollar.png'
import './index.css'
import { getTxnHistory } from '@utils/api/private/history';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export type Props = {
  showTerms: boolean,
  handleClose: () => void
  mode: "ecoin" | "daypass";
};

const TermsOfUse = ({ mode, showTerms, handleClose }: Props) => {
  const { t, i18n } = useTranslation("Login");

  const url = useMemo(() => {
    switch (mode) {
      case "ecoin":
        // Otherwise "s.language is not defined"
        return `https://app.kmb.hk/app1933/eCoin/php/eCoin_TNC.php?lang=${i18n.language?.toUpperCase()}`;
      case "daypass":
        return `https://app.kmb.hk/app1933/day_pass/tnc.php?lang=${i18n.language?.toUpperCase()}`;
      default:
        console.error("Invalid mode");
    }
  }, [mode, i18n.language]);

  return (
    <Modal show={showTerms} onHide={handleClose} size={'lg'} centered dialogClassName='tou'>
      <Modal.Header closeButton>
        <Stack>
          <Modal.Title>{t("terms2")}</Modal.Title>
          {/* <div style={{fontSize:"25px", color:"grey", fontWeight:"bolder"}}>{t("recent10Entries")}</div> */}
        </Stack>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "scroll" }}>
        <iframe src={url} style={{ width: "100%", height: "100%" }}></iframe>
      </Modal.Body>
    </Modal>
  );
}

export default TermsOfUse;