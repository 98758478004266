import React from 'react'
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col, Button, Stack } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { QRCodeSVG } from 'qrcode.react';
import moment from 'moment';
import PointHistory from '../../components/PointHistory';
import dollar from './ecoin_logo.png';
import { getUser, getUserCurrentPass } from '@utils/api/private/user';
import { getPaymentCode } from '@utils/api/private/paymentCode';
import { logout } from '@utils/auth';
import { postLogout } from '@utils/api/private/logout';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import TermsOfUse from '@components/TermsOfUse';
import { Pass } from '@utils/types/Pass';
import { isAxiosError } from 'axios';
import { SwitchModeButton } from '@components/SwitchModeButton';
import "./index.css";
import { RedeemPurchaseDayPassButton } from '@components/RedeemPurchaseDayPassButton';

const Main = () => {
  const { t, i18n } = useTranslation("Main");
  const { t: t1 } = useTranslation("PassExpiryDate");

  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(moment());
  const [targetTime, setTargetTime] = useState(moment().add(15, 'seconds'));
  const [codeValue, setCodeValue] = useState<string|undefined>();
  const [showHistory, setShowHistory] = useState(false);
  const [userId, setUserId] = useState<number|undefined>();
  const [amount, setAmount] = useState("-");
  const [refreshing, setRefreshing] = useState(false);
  const [needLogin, setNeedLogin] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [pass, setPass] = useState<Pass|undefined>();
  const [userCurrentPass, setUserCurrentPass] = useState<Pass|undefined>();
  const [expired, setExpired] = useState(false);
  const passDisplayName = useMemo(() => pass && (i18n.language === "en" ? pass.displayNameEn : pass.displayNameTc), [pass, i18n]);
  
  const currentIsDayPass = useMemo(() => {
    if (pass) {
      // Current account is day pass user
      return true;
    }
    if (userCurrentPass && moment(userCurrentPass.expiryTime).isAfter()) {
      // Current day pass still active
      return true;
    }
    return false;
  }, [pass, userCurrentPass]);

  const currentMode = useMemo(() => {
    if (pass && pass.ownerUserId) {
      // Current account is day pass user
      return "pass";
    }
    if (userCurrentPass && moment(userCurrentPass.expiryTime).isAfter()) {
      return "user";
    }

    return undefined;
  }, [pass, userCurrentPass]);

  const displayLogoutButton = useMemo(() => {
    const appId = localStorage.getItem("appId") || "test";

    if (appId.startsWith("alipay-")) {
      return false;
    }

    return expired && pass && !pass.ownerUserId;
  }, [expired, pass]);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/");
    }
  }, [navigate]);

  const handleGetUser = useCallback(() => {
    getUser().then(data => {
      setUserId(data.id);
      setAmount(Number(data.amount).toFixed(1));
      setPass(data.pass);
    });
    getUserCurrentPass().then(data => {
      setUserCurrentPass(data);
    }).catch();
  }, []);

  const handleLogout = useCallback(() => {
    postLogout()
    .then((data) => {
      if (data.success) {
        logout();
      }
    })
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      handleGetUser();
    }, 15000);

    return () => clearInterval(interval);
  }, [handleGetUser]);

  const refreshQR = useCallback(() => {
    setRefreshing(true);
    getPaymentCode()
    .then(data => {
      setExpired(false);
      setCodeValue(data.paymentCode);
    })
    .catch((error) => {
      if (isAxiosError(error) && error.response?.data?.message) {
        if (error.response?.data?.message === "PassExpired") {
          setExpired(true);
          setCodeValue(undefined);
        } else {
          toast.error(t(`error.${error.response?.data?.message}`));
        }
      }
    })
    .finally(() => {
      setTargetTime(moment().add(15, 'seconds'));
      setRefreshing(false);
    });
  }, [handleLogout]);

  const handleRefreshAll = useCallback(()=> {
    refreshQR();
    handleGetUser();
  }, [refreshQR, handleGetUser])

  useEffect(handleRefreshAll, [handleRefreshAll]);

  useEffect(() => {
    const interval = setInterval(() => {
      const time = moment();
      setCurrentTime(time);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (targetTime.diff(currentTime) <= 0) {
      refreshQR();
    }
  }, [currentTime, targetTime, refreshQR]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (<div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
    <div className="topbar" style={{ position: "sticky", top: 0 }}>
        <Container style={{maxWidth:500 }}>
          <Row style={{alignItems:"center"}}>
            <Col xs={6} style={{flexDirection: "row", display: "flex", justifyContent: "flex-start", alignItems: 'center'}}>
              {/* <img src={dollar} alt='Bus coin' style={{ height:"35px",width:"35px", marginRight:5, alignItems: "center"}}/> */}
              {!pass && <h4 style={{ marginBottom:0, fontWeight: "bold" }}>eCoin: {amount}</h4>}
              {passDisplayName && <h2 style={{ marginBottom:0, fontWeight:"bold" }}> {passDisplayName}</h2>}
            </Col>
            {!pass && <Col style={{justifyContent: "flex-end", display:"flex"}}>
              <div style={{border:"1px solid", minWidth: 130, borderRadius: 20, textAlign: "center", padding: "0 5px 0 5px"}}>
                <Button variant='danger' style={{padding:0, backgroundColor: "transparent"}} onClick={() => setShowHistory(true)}><strong>{t("checkPointUsageRecord")}</strong></Button>
              </div>
            </Col>}
          </Row>
        </Container>
      </div>
      <Stack style={{flex:1}}>
        {pass && <div style={{ fontSize: 24, textAlign: "center", marginBottom: 10 }}>{t1("expiryDate")}: {moment(pass.expiryTime).format("YYYY-MM-DD HH:mm")}</div>}
        <Container style={{maxWidth:302, marginBottom: 35}}>
        {!pass && userId && <div style={{fontSize: 17, textAlign: "center"}} className='mb-2'>{t("userId", { userId })}</div>}
        {/* {pass && <div style={{fontSize: 36, fontWeight: "bold", textAlign: "center", backgroundColor: "#a7142e", color:"white", borderRadius: 10 }} className='mb-2'>
          {passDisplayName}
        </div>} */}
        {codeValue && <div className="text-center mb-2">
          <QRCodeSVG
            value={String(codeValue)}
            fgColor={pass ? "#d55254" : '#3b4851'}
            size={Math.min(windowSize[1] - 140, 280)}
            level='H'
          />
        </div>}
        {expired && <div className="text-center mb-2">
          <h3>{t("expired")}</h3>
        </div>}
        {!expired && <div style={{display: "flex", justifyContent: "center"}} className="mt-3">
          {!refreshing && <div className="text-center" style={{width: 140, backgroundColor: "#3b4852", borderRadius: 15, color: "white", padding: "3px 0 3px 0"}} onClick={handleRefreshAll}>{t("refreshNow")}</div>}
          {refreshing && <div className="text-center" style={{width: 140, backgroundColor: "#3b4852", borderRadius: 15, color: "white", padding: "3px 0 3px 0"}}>{t("refreshing")}</div>}
        </div>}
        {!expired && <div className='text-center mt-2 mb-2'>
          <strong>{t("refreshTimeLeft", { timeLeft: moment.utc(Math.max(0, moment.duration(targetTime.diff(currentTime)).asMilliseconds())).format("mm:ss")})}</strong>
        </div>}
        {!pass && <ol className="remarks" style={{ marginTop: 20, listStylePosition: "inside", textAlign: "center", paddingLeft: 0 }}>
          <li>{t("remarks1")}</li>
          <li>{t("remarks2")}</li>
        </ol>}
        {!currentIsDayPass && <RedeemPurchaseDayPassButton/>}
        {currentMode && <SwitchModeButton afterLoad={handleRefreshAll} currentMode={currentMode}/>}
        {displayLogoutButton && <Button style={{ width: "100%" }} onClick={handleLogout}>{t("button.logout")}</Button>}
      </Container>
     
      
      {/* <Container style={{maxWidth:500}}>
          <Row className="mb-3 mt-2">
            <Col className="text-start " xs={8}><strong>{t("refreshTimeLeft", { timeLeft: moment.utc(moment.duration(targetTime.diff(currentTime)).asMilliseconds()).format("mm:ss")})}</strong></Col>
            {!refreshing && <Col className="text-end text-primary" onClick={handleRefreshAll}><u>{t("refreshNow")}</u></Col>}
            {refreshing && <Col className="text-end text-secondary"><u>{t("refreshing")}</u></Col>}
          </Row>
        </Container> */}
      {/* <Container style={{maxWidth:500}}>
        {userId && <div className='text-black-50' style={{fontSize:"10px"}}>{t("userId", { userId })}</div>}
      </Container> */}
      <PointHistory showHistory={showHistory} handleClose={() => setShowHistory(false)} />
    </Stack> 
    <div className="topbar" style={{marginBottom:0, paddingTop: 16, paddingBottom: 16, height: 35 + 8 + 8, position: "fixed", bottom: 0, left: 0, right: 0}}>
        <Container style={{maxWidth:500}}>
          <Row style={{alignItems:"center"}}>
            <Col xs={8}><Button variant='danger' style={{padding:0, backgroundColor: "transparent"}} onClick={() => setShowTerms(true)}><strong>{t("terms")}</strong></Button></Col>
            <Col xs={1}></Col>
            <Col style={{justifyContent: "flex-end", display:"flex"}}>
             {/* <Button variant='danger' style={{padding:0, backgroundColor: "transparent"}} onClick={handleLogout}><strong>{t("button.logout")}</strong></Button> */}
            </Col>
          </Row>
        </Container>
      </div> 
      <TermsOfUse
        mode={currentIsDayPass ? "daypass" : "ecoin"}
        showTerms={showTerms}
        handleClose={() => setShowTerms(false)}
      />
  </div>
    
  );
}
 
export default Main;