import React from 'react';
import { publicApi } from "..";

export async function postSendOtp(phone:string, turnstileToken:string) {
  const result = await publicApi.post("/login/sendOtp", {
    phone,
    turnstileToken
  });

  return result.data;
}

export async function postVerifyOtp(phone:string, token:string, correlation:string) {
  const result = await publicApi.post("/login/verifyOtp", {
    phone,
    token,
    correlation
  });

  return result.data;
}

export async function postRedeem(
  appId: string,
  redeemCode: string,
  fakeRedeemCode: string,
  passportId?: string,
  currentAccessToken?: string
) {
  const result = await publicApi.post("/login/redeem", {
    appId,
    redeemCode,
    fakeRedeemCode,
    passportId,
    currentAccessToken,
  });

  return result.data;
}

export async function getCheckRedeemCode(appId:string, redeemCode:string) {
  const result = await publicApi.get<{ isPassActive: boolean }>("/login/checkRedeemCode", {
    params: {
      appId,
      redeemCode
    }
  });

  return result.data;
}