import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Core language features
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/array/from';
import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';

import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/object/from-entries';

import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/pad-start';
import 'core-js/features/string/pad-end';

import 'core-js/features/number/is-finite';
import 'core-js/features/number/is-nan';
import 'core-js/features/number/is-integer';

// ES6+ features
import 'core-js/features/promise';
import 'core-js/features/symbol';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/weak-map';
import 'core-js/features/weak-set';

// Web APIs
import 'core-js/web/url';
import 'core-js/web/url-search-params';
import 'whatwg-fetch';

// DOM features
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
