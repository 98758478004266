import React from 'react'
import { getAccessToken, refreshAccessToken } from "@utils/auth";
import axios, { AxiosRequestConfig, isAxiosError } from "axios";

const baseURL = "/api"

export const publicApi = axios.create({
  baseURL: `${baseURL}/public`
});

export const privateApi = axios.create({
  baseURL: `${baseURL}/private`
});

privateApi.interceptors.request.use(async function (config) {
  config.headers.Authorization = `Bearer ${getAccessToken()}`
  return config;
});

privateApi.interceptors.response.use(undefined, async function (error) {
  if (isAxiosError(error)) {
    const originalRequest:(AxiosRequestConfig<any> & {_retry?:boolean})|undefined = error.config;
    if (originalRequest && error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log("Try to refresh access token");
      const refreshResult = await refreshAccessToken();
      if (refreshResult) {
        return privateApi(originalRequest);
      }
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
});