import React from 'react';
import { useCallback } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function PurchaseDayPassButton() {
  const { t } = useTranslation("RedeemCode");

  const purchaseUrl = useCallback(() => {
    const params = new URLSearchParams();

    for (const key of ["key", "locale", ["refreshToken", "token"], ["appId", "appID"]]) {
      let localStorageKey;
      let paramKey;
      
      if (Array.isArray(key)) {
        [localStorageKey, paramKey] = key;
      } else {
        paramKey = key;
        localStorageKey = key;
      }

      if (paramKey && localStorageKey) {
        const value = localStorage.getItem(localStorageKey);
        if (value) {
          params.set(paramKey, value);
        }
      }
    }
  
    return `https://app.kmb.hk/app1933/day_pass/?${params}`;
  }, []);

  return <Button
    href={purchaseUrl()}
    style={{ width: "100%", marginTop: 10, fontWeight: "bold" }}
    >
      {t("button.purchaseDayPass")}
    </Button>
}