import React from 'react';
import { PurchaseDayPassButton } from '@components/PurchaseDayPassButton';
import TermsOfUse from '@components/TermsOfUse';
import { getCheckRedeemCode, postRedeem } from '@utils/api/public/login';
import { storeAccessToken, storeRefreshToken } from '@utils/auth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import "./index.css";

const RedeemCode = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("RedeemCode");
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [displayRedeemCode, setDisplayRedeemCode] = useState("");
  const [editable, setEditable] = useState(true);
  const [redeemCode, setRedeemCode] = useState("");
  const [failoverMode, setFailoverMode] = useState(false);
  const [autoRedirectToMain, setAutoRedirectToMain] = useState(false);

  const [passportId, setPassportId] = useState("");

  const plainRedeemCode = useMemo(() => redeemCode.replace(/-/g, ""), [redeemCode]);
  const disabled = useMemo(() => loading || !(agreedTerms && plainRedeemCode.length === 16), [loading, agreedTerms, plainRedeemCode]);

  const handleSwitchToFailoverMode = useCallback(() => {
    setFailoverMode(true);
    setRedeemCode(oldRedeemCode => oldRedeemCode.replaceAll(/\D/g, ""));
  }, []);

  const handleRedeem = useCallback((noValidate = false) => {
    const appId = localStorage.getItem("appId") || "test";
    if (!noValidate) {
      if (appId.startsWith("alipay-") && passportId.length !== 4) {
        toast.error(t("error.InvalidPassportId"));
        return;
      }
    }

    setLoading(true);
    
    const currentAccessToken = localStorage.getItem("accessToken") || undefined;

    postRedeem(
      appId,
      plainRedeemCode,
      displayRedeemCode,
      passportId ? passportId : undefined,
      currentAccessToken
    )
    .then((response) => {
      storeRefreshToken(response.refreshToken, response.refreshTokenExpiryDate);
      storeAccessToken(response.accessToken, response.accessTokenExpiryDate)
      navigate(`/main?token=${response.refreshToken}`);
    })
    .catch(error => {
      toast.error(t(`error.${error.response?.data?.message || error.message}`));
    })
    .finally(() => {
      setLoading(false);
    })
  }, [plainRedeemCode, displayRedeemCode, passportId]);

  const handleCheckRedeemCode = useCallback((code:string) => {
    const appId = localStorage.getItem("appId") || "test";

    if (appId.startsWith("alipay-")) {
      getCheckRedeemCode(appId, code)
      .then(({ isPassActive }) => {
        if (isPassActive) {
          setAutoRedirectToMain(true);
        }
      });
    }
  }, [handleRedeem]);

  const handleRedeemCodeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRedeemCode(e.target.value);
  }, []);

  const handleKeyUp = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((localStorage.getItem("appId") || "test").startsWith("alipay-")) return;
    if (e.key === "Enter" && !(loading || plainRedeemCode.length !== 16)) {
      handleRedeem();
    }
  }, [handleRedeem, loading, plainRedeemCode.length]);

  useEffect(() => {
    const code = searchParams.get("code");
    const fcode = searchParams.get("fcode");
    const edit = searchParams.get("edit");

    if (code) {
      setRedeemCode(code);
      handleCheckRedeemCode(code);
    }

    if (fcode) {
      setDisplayRedeemCode(fcode);
      setEditable(false);
    } else {
      setEditable(edit !== "false");
    }
  }, [searchParams]);

  useEffect(() => {
    // To reuse handleRedeem() without additional parameters, need to wait for redeemCode to be set before calling handleRedeem()
    if (autoRedirectToMain && plainRedeemCode) {
      handleRedeem(true);
    }
  }, [autoRedirectToMain, plainRedeemCode]);

  return <>
    <div className="topbar">
      <Container>
        <Row>
          <Col xs={8}>
            <span className="appbar-title" style={{ width: "100%" }}>{t("appbar.title")}</span>
          </Col>
          <Col style={{ textAlign: "right", alignContent: "center" }}>
            {!(localStorage.getItem("appId") || "test").startsWith(
              "alipay-"
            ) && (
              <a className="back" href={`/?token=${localStorage.getItem("refreshToken")}`}><span>✕</span></a>
            )}
          </Col>
        </Row>
      </Container>
    </div>
    <Container style={{maxWidth:500}}>
      <Row className='text-center mt-5'>
        <Col className='my-4'><h2>{t("title")}</h2></Col>
      </Row>
      <Row className='mb-4 text-center'>
        <Col>
          <Form.Group>
            {failoverMode || displayRedeemCode ? (
              <Form.Control
                style={{ textAlign: "center", fontSize: "1.2em" }}
                inputMode="numeric"
                placeholder={t("textfield.redeemCodeFailover")}
                className='p-3'
                value={displayRedeemCode ||redeemCode}
                onChange={handleRedeemCodeChange}
                onKeyUp={handleKeyUp}
                disabled={!editable}
                maxLength={19}
              />
            ) : (
              <IMaskInput
                mask="0000-0000-0000-0000"
                type="text"
                inputMode="numeric"
                style={{ textAlign: "center", fontSize: "1.2em", width: "100%", padding: "1rem" }}
                placeholder={t("textfield.redeemCode")}
                className='form-control p-3'
                value={redeemCode}
                onAccept={(value: any) => setRedeemCode(value)}
                onKeyUp={handleKeyUp}
                disabled={!editable}
              />
            )}
            {!failoverMode && editable && <div>
              <a href="javascript:void(0)" onClick={handleSwitchToFailoverMode}>{t("failoverLink")}</a>
            </div>}
            {(localStorage.getItem("appId") || "test").startsWith(
              "alipay-"
            ) && (
              <Form.Control
                style={{ textAlign: "center", fontSize: "1.2em" }}
                placeholder={t("textfield.passportId")}
                className="p-3"
                value={passportId}
                onChange={(event) => setPassportId(event.target.value)}
                maxLength={4}
              />
            )}
            <Form.Check
              checked={agreedTerms}
              onChange={(e) => {
                setAgreedTerms(e.target.checked);
              }}
              inline={true}
              className="mt-4"
            />
            <>{t('terms1')}</><a className='terms' onClick={() => setShowTerms(true)}>{t('terms2')}</a>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className='w-100'
            variant='danger'
            onClick={() => handleRedeem()}
            disabled={disabled}
          >
            <div><strong>{t("button.redeem")}</strong></div>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
        {!(localStorage.getItem("appId") || "test").startsWith(
          "alipay-"
        ) && <PurchaseDayPassButton/>}
        </Col>
      </Row>
    </Container>
    <TermsOfUse mode="daypass" showTerms={showTerms} handleClose={() => setShowTerms(false)}/>
  </>
}
 
export default RedeemCode;