import React from 'react';
import { useMemo } from "react";

export function RedeemPurchaseDayPassButton() {
  const href = useMemo(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      return `/redeem?token=${refreshToken}`;
    } else {
      return "/redeem";
    }
  }, []);

  const imageSrc = useMemo(() => {
    const locale = localStorage.getItem("locale") || "tc";
    return `https://app.kmb.hk/app1933/eCoin/image/day_pass_btn_${locale}.jpg`
  }, []);

  // return <></>
  
  return <a href={href}><img src={imageSrc} style={{ width: "100%", marginTop: 10 }}/></a>
}